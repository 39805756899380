import React from "react"
import Logo from "../../static/logo.svg"

const Footer = ({ props }) => (
    <footer className="w-full bg-blue-500 py-12">
        <div className="mx-auto max-w-6xl w-11/12 flex sm:flex-row flex-col items-center justify-between bg-blue-500 text-white text-l">
            <div className="text-sm text-center sm:text-left">
                <p className="text-base font-semibold mb-5">Individuelles Rückentraining zur Stärkung der tiefen Wirbelsäulenmuskulatur.</p>
                <p>info@irt-mtt.de</p>
                <p>06172 6772-293</p>
                <p className="mt-5">Zeppelinstraße 24</p>
                <p>61352 Bad Homburg</p>
                <p>gegenüber Hochtaunuskliniken</p>
            </div>
            <div className="sm:w-64 mt-10 sm:m-0 ml-12">
                <img
                    src={Logo}
                    alt=""
                    className="w-40 sm:float-right border-white"
                ></img>
            </div>
        </div>
    </footer>
)

export default Footer