import React from "react"
import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => (
    <>
      <div className="bg-red-500">
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between flex-wrap">
            <div className="flex items-center">
              <span className="flex p-2 rounded-lg bg-white">
              </span>
              <p className="ml-3 font-medium text-white truncate">
                <span className="inline">Unser zweiter Standort in Frankfurt am Main!</span>
              </p>
            </div>
            <div className="mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
              <a
                href="/kontakt"
                className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-red-400 bg-white hover:bg-indigo-50"
              >
                Mehr erfahren
              </a>
            </div>
          </div>
        </div>
      </div>
      <Header />

      <div className="flex flex-col items-center bg-blue-500">
        <div className="h-px w-11/12 max-w-6xl bg-white"></div>
        <main className="flex flex-col items-center w-full">{children}</main>
        <div className="h-px w-11/12 max-w-6xl bg-white"></div>
      </div>
      <Footer />

    </>
  )

export default Layout
