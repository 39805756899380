import { Link } from "gatsby"
import React from "react"

const Nelement = ({ pageName, pageLink, listClass, listAnim, listDelay, toggleScroll }) => (
  <li className={"md:ml-12 my-8 md:my-3 " + listClass} style={{transitionDelay: listDelay, transition: listAnim}}>
    <button onClick={toggleScroll}>
    <Link className="no-underline text-2xl md:text-lg font-normal text-white hover:opacity-50" to={pageLink}>
      {pageName}
    </Link>
    </button>
  </li>
)

class Header extends React.Component {
  state = {
    isOpen: false,
    overflowChange: "overflow-hidden",
  }
  
  toggleScroll = () => {
    document.body.style.position = "static";
    document.body.style.overflow = "auto";
    this.setState({isOpen: false});
    this.setState({overflowChange: "overflow-hidden"});
    setTimeout(() => {
      document.getElementById("scrolled-nav-div").scrollTop = 0;
    }, 700);
  }

  toggleMenu = () => {
    if(this.state.isOpen) {
      document.body.style.position = "static";
      document.body.style.overflow = "auto";
      this.setState({overflowChange: "overflow-hidden"});
      setTimeout(() => {
        document.getElementById("scrolled-nav-div").scrollTop = 0;
      }, 700);
    } else {
      document.body.style.position = "fixed";
      document.body.style.overflow = "hidden";
      document.body.style.width = "100%";
      setTimeout(() => {
        this.setState({overflowChange: "overflow-auto"});
      }, 700);
    }
    this.setState((prevState) => {
      return {isOpen: !prevState.isOpen};
    });
  }

  render() {
    const classBg = this.state.isOpen ? 'max-h-10000' : 'max-h-0';
    const classList = this.state.isOpen ? 'opacity-100 mt-4' : 'opacity-0 mt-0';
    const animBg = this.state.isOpen ? 'max-height 0.7s ease-in-out' : 'max-height 0.7s cubic-bezier(0, 1, 0, 1)';
    const animList = this.state.isOpen ? 'opacity 0.5s linear, margin-top 0.5s ease-in-out' : 'opacity 0.5s linear, margin-top 0.5s ease-in-out'; //refactor
    let animDelayArr = ["0s", "0.1s", "0.2", "0.3s"]
    const animDelay = this.state.isOpen ? animDelayArr : animDelayArr.reverse();
    const hamIconTop = this.state.isOpen ? "rotate(45deg)" : 'rotate(0deg)';
    const hamIconTopClass = this.state.isOpen ? "5px" : '0';
    const hamIconBot = this.state.isOpen ? "rotate(-45deg)" : 'rotate(0deg)';
    const hamIconBotClass = this.state.isOpen ? "-5px" : '0';
    const hamIconMid = this.state.isOpen ? 'invisible' : 'visibile';
    const hamIconAnim = this.state.isOpen ? "top 0.3s ease, transform 0.3s ease 0.3s" : "top 0.3s ease 0.3s, transform 0.3s ease 0s";
    return (
      <header className="relative bg-blue-500">
        <div className="max-w-6xl w-11/12 relative flex justify-between mx-auto">
          <button onClick={this.toggleScroll}>
          <Link className="no-underline text-3xl font-medium inline-block text-white hover:opacity-50" to="/">
            IRT
          </Link>
          </button>
          <button onClick={this.toggleMenu} className="cursor-pointer text-xl border border-solid border-transparent rounded bg-transparent block md:hidden outline-none focus:outline-none" type="button">
            <span className="block relative w-6 h-px rounded-sm bg-white" style={{top: hamIconTopClass, transform: hamIconTop, transition: hamIconAnim}}></span>
            <span className={"block relative w-6 h-px rounded-sm bg-white mt-1 " + hamIconMid} style={{transitionDelay: "0.3s"}}></span>
            <span className="block relative w-6 h-px rounded-sm bg-white mt-1" style={{top: hamIconBotClass, transform: hamIconBot, transition: hamIconAnim}}></span>
          </button>
          <div className="hidden md:flex items-center">
            <ul className="flex flex-col md:flex-row list-none">
                <Nelement pageName="Unser Konzept" pageLink="/unser-konzept"/>
                <Nelement pageName="Team" pageLink="/team"/>
                <Nelement pageName="Häufige Fragen" pageLink="/haeufige-fragen"/>
                <Nelement pageName="Kontakt" pageLink="/kontakt"/>
            </ul>
          </div>
        </div>
        <div className={"md:hidden absolute z-50 bg-blue-500 w-full h-screen overflow-hidden " + classBg} style={{transition: animBg}}>
          <div className={"h-full " + this.state.overflowChange} id="scrolled-nav-div">
            <ul className="mt-12 flex flex-col md:flex-row justify-center list-none items-center pb-5">
                <Nelement pageName="Unser Konzept" pageLink="/unser-konzept" listClass={classList} listAnim={animList} listDelay={animDelay[0]} toggleScroll={this.toggleScroll}/>
                <Nelement pageName="Team" pageLink="/team" listClass={classList} listAnim={animList} listDelay={animDelay[1]} toggleScroll={this.toggleScroll}/>
                <Nelement pageName="Häufige Fragen" pageLink="/haeufige-fragen" listClass={classList} listAnim={animList} listDelay={animDelay[2]} toggleScroll={this.toggleScroll}/>
                <Nelement pageName="Kontakt" pageLink="/kontakt" listClass={classList} listAnim={animList} listDelay={animDelay[3]} toggleScroll={this.toggleScroll}/>
            </ul>
          </div>
        </div>
    </header>
  )
}}

export default Header
